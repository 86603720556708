import { createActions, handleActions } from "redux-actions";

import { fetchShopData } from "../utilities";

/*
 *** ACTION CREATORS
 */

const actions = createActions({
  SUBSCRIPTION_PLANS: {
    REQUEST: undefined,
    RESPONSE: undefined,
  },
});

/*
 *** THUNKS
 */

export const fetchSubscriptionPlans = () => (dispatch) => {
  dispatch(actions.subscriptionPlans.request());

  return fetchShopData("/subscriptions/plans/")
    .then(async (res) => {
      if (res.status !== 200) {
        return dispatch(
          actions.subscriptionPlans.response(new Error(res.statusText))
        );
      }

      const results = (await res.json()).results;

      return dispatch(actions.subscriptionPlans.response(results));
    })
    .catch((error) => dispatch(actions.subscriptionPlans.response(error)));
};

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      actions.subscriptionPlans.request,
      (state) => ({
        ...state,
        isFetching: true,
        error: null,
      }),
    ],
    [
      actions.subscriptionPlans.response,
      (state, { error, payload }) => {
        if (error) {
          return {
            ...state,
            isFetching: false,
            error: payload.message,
          };
        }

        return {
          ...state,
          isFetching: false,
          list: payload,
        };
      },
    ],
  ]),
  { isFetching: false, error: null }
);
