import { createActions, handleActions } from "redux-actions";
import { normalize } from "normalizr";

import { getData, getSlugId } from "../utilities";
import { story, storeStories, resetStories } from "./stories";

/*
 *** ACTION CREATORS
 */

const actions = createActions({
  VIDEO_PAGE: {
    REQUEST: [(payload) => payload, (_, slug) => ({ slug })],
    RESPONSE: [(payload) => payload, (_, slug) => ({ slug })],
  },
});

/*
 *** THUNKS
 */

export const fetchVideoPage = (slug, previewToken) => (dispatch) => {
  dispatch(actions.videoPage.request(undefined, slug));

  return getData(`/stories/by-slug/${slug}/${previewToken ? previewToken : ""}`)
    .then(async (res) => {
      if (res.status === 404) {
        return dispatch(
          actions.videoPage.response(new Error(res.status), slug)
        );
      }

      if (res.status !== 200) {
        return dispatch(
          actions.videoPage.response(new Error(res.statusText), slug)
        );
      }

      const results = (await res.json()).results;
      const contextual_stories = normalize(results.contextual_stories, [story]);
      results.contextual_stories = contextual_stories.result;
      const videoPage = normalize(results, story);

      dispatch(
        storeStories({
          ...contextual_stories.entities.stories,
          ...videoPage.entities.stories,
        })
      );

      return dispatch(actions.videoPage.response(undefined, slug));
    })
    .catch((error) => dispatch(actions.videoPage.response(error, slug)));
};

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      actions.videoPage.request,
      (state, { meta }) => ({
        ...state,
        [getSlugId(meta.slug)]: { isFetching: true, error: null },
      }),
    ],
    [
      actions.videoPage.response,
      (state, { error, payload, meta }) => {
        if (error) {
          return {
            ...state,
            [getSlugId(meta.slug)]: {
              isFetching: false,
              error: payload.message,
            },
          };
        }

        return {
          ...state,
          [getSlugId(meta.slug)]: {
            isFetching: false,
            error: null,
            isLoaded: true,
          },
        };
      },
    ],
    [resetStories, () => ({})],
  ]),
  {}
);

/*
 *** SELECTORS
 */

export const getVideoPage = (state, slug, storiesBySlug) => {
  const slugId = getSlugId(slug);
  const videoPage = state[slugId];

  if (!videoPage) {
    return {
      isFetching: false,
      error: null,
      isLoaded: false,
    };
  }

  let video;
  if (videoPage.isLoaded) {
    video = { ...storiesBySlug[slugId] };
    video.contextual_stories = video.contextual_stories.map(
      (slug) => storiesBySlug[slug]
    );
  }

  return {
    isFetching: videoPage.isFetching,
    error: videoPage.error,
    isLoaded: videoPage.isLoaded || false,
    video,
  };
};
