import React, { useState, useEffect } from "react";
import styled from "styled-components";
import chroma from "chroma-js";

import { campaignMenuHeight, mobileCampaignMenuHeight } from "../../theme";

const Container = styled.a`
  background: ${chroma("#2c0879").alpha(0.95)};
  color: white;
  height: ${campaignMenuHeight};
  padding: 12px 0;
  text-align: center;
  position: fixed;
  text-decoration: none;
  top: 0;
  transition: 0.3s;
  width: 100%;
  z-index: 20000;

  &:hover {
    background: ${chroma("#F22552").alpha(0.95)};
  }

  @media (max-width: 767px) {
    height: ${mobileCampaignMenuHeight};
  }

  .flex {
    align-items: center;
    display: flex;
    justify-content: center;

    .triangle {
      border-bottom: 9px solid transparent;
      border-top: 9px solid transparent;
      display: inline-block;
      height: 0;
      width: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 30000;

      &.left {
        border-left: 15px solid rgba(254, 217, 155, 0.8);
        transform: translateY(-50%) translateX(calc(-50% + -180px));
      }

      &.right {
        border-right: 15px solid rgba(254, 217, 155, 0.8);
        transform: translateY(-50%) translateX(calc(-50% + 180px));
      }

      @media (max-width: 767px) {
        &.left {
          transform: translateY(-50%) translateX(calc(-50% + -155px));
        }

        &.right {
          transform: translateY(-50%) translateX(calc(-50% + 155px));
        }
      }
    }

    > div {
      margin: 0 10px;
    }
  }

  .title {
    background: -webkit-linear-gradient(#ffe7c1 0%, #fed99b 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.2rem;
    font-weight: 700;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }

  .text {
    font-size: 1.7rem;
    font-weight: 600;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 1.5rem;
    }
  }
`;

function CampaignMenu() {
  return (
    <Container href="https://campagne.lemediatv.fr" target="_blank">
      <div className="flex">
        <span className="triangle left" />
        <div>
          <div className="text">Pour le pluralisme à la télévision</div>
          <div className="title">Je signe la pétition !</div>
        </div>
        <span className="triangle right" />
      </div>
    </Container>
  );
}

export default CampaignMenu;
