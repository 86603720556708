import { createActions, handleActions } from "redux-actions";
import * as Sentry from "@sentry/browser";

import { fetchShopData } from "../utilities";
import { actions as userActions } from "./user";

const actions = createActions({
  PAYMENT_METHOD: {
    REQUEST: undefined,
    RESPONSE: undefined,
  },
});

export const updatePaymentMethod = (
  subscriptionId,
  paymentMethod,
  stripeConfirmation
) => async (dispatch) => {
  dispatch(actions.paymentMethod.request());

  try {
    const setupPaymentRes = await fetchShopData(
      `/account/subscriptions/${subscriptionId}/stripe/setup/${paymentMethod}/`,
      { method: "POST" }
    );

    if (setupPaymentRes.status !== 200) {
      return dispatch(
        actions.paymentMethod.response(new Error(setupPaymentRes.statusText))
      );
    }

    const clientSecret = (await setupPaymentRes.json()).client_secret;

    const stripeConfirmationRes = await stripeConfirmation(clientSecret);

    if (stripeConfirmationRes.error) {
      return dispatch(
        actions.order.response(new Error(stripeConfirmationRes.error.message))
      );
    }

    Sentry.setContext("request", {
      stripe_payment_method_id:
        stripeConfirmationRes.setupIntent.payment_method,
    });
    const updateRes = await fetchShopData(
      `/account/subscriptions/${subscriptionId}/stripe/payment-methods/`,
      {
        method: "POST",
        body: JSON.stringify({
          stripe_payment_method_id:
            stripeConfirmationRes.setupIntent.payment_method,
        }),
      }
    );

    if (updateRes.status !== 200) {
      return dispatch(
        actions.paymentMethod.response(new Error(updateRes.statusText))
      );
    }

    const results = (await updateRes.json()).results;
    await dispatch(userActions.user.response(results));

    return dispatch(actions.paymentMethod.response());
  } catch (error) {
    return dispatch(actions.paymentMethod.response(error));
  }
};

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      actions.paymentMethod.request,
      () => ({
        isFetching: true,
        error: null,
      }),
    ],
    [
      actions.paymentMethod.response,
      (_, { error, payload }) => {
        if (error) {
          Sentry.captureException(payload);

          return {
            isFetching: false,
            error: payload.message,
          };
        }

        return {
          isFetching: false,
          error: null,
        };
      },
    ],
  ]),
  { isFetching: false, error: null }
);
