import { createActions, handleActions } from "redux-actions";
import * as Sentry from "@sentry/browser";

import {
  fetchShopData,
  setAccountToken,
  setSsrAccountToken,
  removeAccountToken,
} from "../utilities";
import { resetStories } from "./stories";

/*
 *** ACTION CREATORS
 */

export const actions = createActions({
  USER: {
    LOGOUT: undefined,
    REQUEST: undefined,
    RESPONSE: undefined,
  },
});

export const login = (data) => (dispatch) => {
  dispatch(actions.user.request());

  Sentry.setContext("request", data);
  return fetchShopData("/account/login", {
    method: "POST",
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      if (res.status === 403) {
        return dispatch(
          actions.user.response(new Error("Email ou mot de passe incorrect."))
        );
      }

      if (res.status !== 200) {
        return dispatch(actions.user.response(new Error(res.statusText)));
      }

      setAccountToken(res.headers.get("Authorization").replace("Bearer ", ""));
      dispatch(resetStories());

      const results = (await res.json()).results;
      dispatch(actions.user.response(results));
    })
    .catch((error) => dispatch(actions.user.response(error)));
};

export const logout = () => (dispatch) => {
  dispatch(actions.user.logout());
  dispatch(resetStories());
  removeAccountToken();
};

export const fetchAccount = (ssrRes) => (dispatch) => {
  dispatch(actions.user.request());

  return fetchShopData("/account/user/")
    .then(async (res) => {
      if (res.status === 403) {
        dispatch(logout());
        return dispatch(
          actions.user.response(new Error("Authentification invalide."))
        );
      }

      if (res.status !== 200) {
        dispatch(logout());
        return dispatch(actions.user.response(new Error(res.statusText)));
      }

      const token = res.headers.get("Authorization").replace("Bearer ", "");
      if (ssrRes) {
        setSsrAccountToken(ssrRes, token);
      } else {
        setAccountToken(token);
      }
      dispatch(resetStories());

      const results = (await res.json()).results;
      dispatch(actions.user.response(results));
    })
    .catch((error) => {
      dispatch(logout());
      return dispatch(actions.user.response(error));
    });
};

export const createAccount = (data) => (dispatch) => {
  dispatch(actions.user.request());

  Sentry.setContext("request", data);
  return fetchShopData("/account/user/", {
    method: "POST",
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      if (res.status === 409) {
        return dispatch(
          actions.user.response(
            new Error("Un utilisateur avec cette adresse e-mail existe déjà.")
          )
        );
      }

      if (res.status !== 200) {
        return dispatch(actions.user.response(new Error(res.statusText)));
      }

      setAccountToken(res.headers.get("Authorization").replace("Bearer ", ""));
      dispatch(resetStories());

      const results = (await res.json()).results;
      dispatch(actions.user.response(results));
    })
    .catch((error) => dispatch(actions.user.response(error)));
};

export const updateAccount = (data) => (dispatch) => {
  dispatch(actions.user.request());

  Sentry.setContext("request", data);
  return fetchShopData("/account/user/", {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "X-Keys": Object.keys(data).join(","),
    },
  })
    .then(async (res) => {
      if (res.status !== 200) {
        return dispatch(actions.user.response(new Error(res.statusText)));
      }

      const results = (await res.json()).results;
      dispatch(actions.user.response(results));
    })
    .catch((error) => dispatch(actions.user.response(error)));
};

export const addPendingShares = () => (dispatch) => {
  dispatch(actions.user.request());

  return fetchShopData("/account/user/shares/", {
    method: "POST",
  })
    .then(async (res) => {
      if (res.status !== 200) {
        return dispatch(actions.user.response(new Error(res.statusText)));
      }

      const results = (await res.json()).results;
      dispatch(actions.user.response(results));
    })
    .catch((error) => dispatch(actions.user.response(error)));
};

export const updateSubscription = (planProductId) => (dispatch) => {
  dispatch(actions.user.request());

  Sentry.setContext("request", { subscription_plan_id: planProductId });
  return fetchShopData("/account/user/subscription/", {
    method: "PUT",
    body: JSON.stringify({ subscription_plan_id: planProductId }),
  })
    .then(async (res) => {
      if (res.status !== 200) {
        return dispatch(actions.user.response(new Error(res.statusText)));
      }

      const results = (await res.json()).results;
      return dispatch(actions.user.response(results));
    })
    .catch((error) => dispatch(actions.user.response(error)));
};

export const cancelSubscription = () => (dispatch) => {
  dispatch(actions.user.request());

  return fetchShopData("/account/user/subscription/", {
    method: "DELETE",
  })
    .then(async (res) => {
      if (res.status !== 200) {
        return dispatch(actions.user.response(new Error(res.statusText)));
      }

      const results = (await res.json()).results;
      return dispatch(actions.user.response(results));
    })
    .catch((error) => dispatch(actions.user.response(error)));
};

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      actions.user.logout,
      () => ({
        isConnected: false,
        account: { isFetching: false, error: null },
      }),
    ],
    [
      actions.user.request,
      (state) => ({
        ...state,
        account: { ...state.account, isFetching: true, error: null },
      }),
    ],
    [
      actions.user.response,
      (state, { error, payload }) => {
        if (error) {
          Sentry.captureException(payload);

          return {
            ...state,
            account: {
              ...state.account,
              isFetching: false,
              error: payload.message,
            },
          };
        }

        Sentry.setUser({ email: payload.email });

        return {
          ...state,
          isConnected: true,
          account: { ...state.account, isFetching: false, ...payload },
        };
      },
    ],
  ]),
  { isConnected: false, account: { isFetching: false, error: null } }
);

/*
 *** SELECTORS
 */

export const getAccount = (state) => state.account;

export const getAuth = (state) => ({ isConnected: state.isConnected });

export const getSubscription = (state) => {
  if (state.account.active_subscription) {
    return state.account.active_subscription;
  }

  if (
    state.account.canceled_subscription &&
    state.account.canceled_subscription.status === "CANCEL_AT_PERIOD_END"
  ) {
    return state.account.canceled_subscription;
  }
};
