import { createActions, handleActions, handleAction } from "redux-actions";
import { normalize } from "normalizr";

import { getData } from "../utilities";
import { topic, storeTopics } from "./topics";
import { actions as topicPageActions } from "./topicPage";
const { topicPage } = topicPageActions;

/*
 *** ACTION CREATORS
 */

const actions = createActions({
  TOPICS_PAGE: {
    REQUEST: [(payload) => payload, (_, page) => ({ page })],
    RESPONSE: [
      (payload) => payload,
      (_, page, nbrPages) => ({ page, nbrPages }),
    ],
  },
});

/*
 *** THUNKS
 */
export const fetchTopicsPage = (page = 1) => (dispatch) => {
  dispatch(actions.topicsPage.request(undefined, page));

  return getData("/topics/", {
    page,
    per_page: 12,
  })
    .then(async (res) => {
      if (res.status === 404) {
        return dispatch(
          actions.topicsPage.response(new Error(res.status), page)
        );
      }

      if (res.status !== 200) {
        return dispatch(
          actions.topicsPage.response(new Error(res.statusText), page)
        );
      }

      const results = (await res.json()).results;
      const topics = normalize(results, [topic]);

      dispatch(storeTopics(topics.entities.topics));
      dispatch(topicPage.register(topics.result));

      return dispatch(
        actions.topicsPage.response(
          topics.result,
          page,
          parseInt(res.headers.get("x-pagination-pages"), 10)
        )
      );
    })
    .catch((error) => dispatch(actions.topicsPage.response(error, page)));
};

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      actions.topicsPage.request,
      (state, { meta }) => ({
        ...state,
        pages: {
          ...state.pages,
          [meta.page]: { isFetching: true, error: null },
        },
      }),
    ],
    [
      actions.topicsPage.response,
      (state, { error, payload, meta }) => {
        if (error) {
          return {
            ...state,
            pages: {
              ...state.pages,
              [meta.page]: {
                isFetching: false,
                error: payload.message,
              },
            },
          };
        }

        return {
          pages: {
            ...state.pages,
            [meta.page]: { isFetching: false, error: null, topics: payload },
          },
          nbrPages: meta.nbrPages,
        };
      },
    ],
  ]),
  { pages: {} }
);

/*
 *** SELECTORS
 */

export const getTopicsPage = (state, page, topicsBySlug) => {
  const topicList = state.pages[page];

  if (!topicList) {
    return {
      isFetching: false,
      error: null,
      nbrPages: state.nbrPages,
      topics: [],
    };
  }

  return {
    isFetching: topicList.isFetching,
    error: topicList.error,
    nbrPages: state.nbrPages,
    topics: topicList.topics
      ? topicList.topics.map((slug) => topicsBySlug[slug])
      : [],
  };
};
