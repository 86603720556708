import React, { useState } from "react";
import { useForm, ErrorMessage } from "react-hook-form";
import fetch from "cross-fetch";
import * as Sentry from "@sentry/browser";

import { getLocalNewsletters, addLocalNewsletters } from "../utilities";

function Newsletter() {
  const [isLoading, setIsLoading] = useState(false);
  const { register, errors, handleSubmit } = useForm();

  const localNewsletters = getLocalNewsletters();

  async function onSubmit(data) {
    setIsLoading(true);
    try {
      await fetch(`${process.env.RAZZLE_HOSTNAME}/newsletter`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
    } catch (error) {
      Sentry.captureException(error, {
        request: data,
      });
    }

    addLocalNewsletters(data.email, { global: true });
    setIsLoading(false);
  }

  if (localNewsletters.global) {
    return (
      <p className="already-subscribed">
        <i className="fa-fw fas fa-info-circle" />
        Vous êtes inscrit à notre newsletter !
      </p>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <input
          disabled={isLoading}
          name="email"
          type="email"
          placeholder="Email"
          ref={register({
            required: "L'adresse email est requise.",
            pattern: {
              value:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "L'adresse email doit être valide.",
            },
          })}
        />
        <button>
          {isLoading ? <i className="fas fa-spinner fa-spin" /> : "Ok"}
        </button>
      </div>
      <ErrorMessage errors={errors} name="email">
        {({ message }) => <p>{message}</p>}
      </ErrorMessage>
    </form>
  );
}

export default Newsletter;
