import React from "react";
import styled from "styled-components";

import { gradients, menuOffset } from "../theme";

const Container = styled.a`
  background: ${gradients.purpleToPink()};
  color: white;
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 15px 29px 12px;
  position: fixed;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: calc(${menuOffset} + 30px);
  text-transform: uppercase;
  transition: 0.3s;
  z-index: 10000;

  @media (max-width: 1023px) {
    display: none;
  }

  :hover {
    padding: 15px 45px 12px;
  }
`;

function StickyDonation() {
  return (
    <Container
      href="https://dons.lemediatv.fr"
      target="_blank"
      rel="noopener noreferrer"
    >
      Je fais un don
    </Container>
  );
}

export default StickyDonation;
