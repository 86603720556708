import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import BurgerSqueeze from "@animated-burgers/burger-squeeze";
import "@animated-burgers/burger-squeeze/dist/styles.css";

import {
  getProgramList,
  getCategoryList,
  getPodcastList,
} from "../../configureStore";
import {
  campaignMenuHeight,
  mobileCampaignMenuHeight,
  menuHeight,
  mobileMenuHeight,
  colors,
  gradients,
} from "../../theme";

const Container = styled.nav`
  color: white;
  max-width: 100%;
  overflow: ${(props) => (props.isOpen ? "hidden" : "visible")};
  position: ${(props) => (props.isTransparent ? "absolute" : "fixed")};
  top: ${campaignMenuHeight};
  transform: ${(props) =>
    props.isOpen ? "translateX(0)" : "translateX(-320px)"};
  transition: 0.3s;
  z-index: 20000;

  @media (max-width: 767px) {
    top: ${mobileCampaignMenuHeight};
  }

  header {
    display: flex;
    justify-content: space-between;
    height: ${menuHeight};
    width: 408px;

    @media (max-width: 767px) {
      height: ${mobileMenuHeight};
      width: 380px;
    }
  }

  h1 {
    background: ${colors.menuTitleColor};
    font-size: 2.8rem;
    font-weight: 500;
    line-height: ${menuHeight};
    padding-left: 40px;
    text-transform: uppercase;
    width: 320px;

    @media (max-width: 767px) {
      line-height: ${mobileMenuHeight};
    }
  }

  > div {
    transition: 0s 0.3s;
    ${(props) => (!props.isOpen ? "max-height: 0" : "transition: 0s")};
  }
`;

const Burger = styled.button`
  background: ${(props) =>
    props.isTransparent
      ? "transparent"
      : "linear-gradient(transparent, #FF4360) #A222CC"};
  height: ${menuHeight};
  text-align: center;
  transition: 0.3s;
  width: ${menuHeight};

  ${(props) =>
    props.isOpen &&
    `
    background: ${colors.menuTitleColor};
  `}

  @media (max-width: 767px) {
    height: ${mobileMenuHeight};
    width: ${mobileMenuHeight};

    ${(props) =>
      props.isOpen &&
      `
    transform: translateX(-${mobileMenuHeight});
    `}
  }

  :hover {
    background: #ff4360;
  }

  .burger {
    font-size: 8px;
    margin: auto;
  }
`;

const Menu = styled.div`
  background: linear-gradient(${colors.menuTitleColor}, #a21d4b);
  max-height: calc(100vh - ${menuHeight});
  overflow: auto;
  width: 320px;

  @media (max-width: 767px) {
    max-height: calc(100vh - ${mobileMenuHeight});
  }

  a {
    color: white;
    text-decoration: none;
  }
`;

const Primary = styled.div`
  margin: auto;
  padding: 13px 6px 15px;
  width: 252px;

  h2 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 0.015rem;
    padding-bottom: 13px;
    text-transform: uppercase;
  }

  ul {
    list-style: none;
    margin: 14px 0 36px;
    padding: 0;
  }

  a {
    border-radius: 4px;
    display: block;
    line-height: 22px;
    margin: 0 -6px;
    padding: 0 6px;
    transition: 0.3s;

    :hover {
      background: rgba(255, 255, 255, 0.2);
      color: #3ff0a0;
      font-weight: 600;
      text-indent: 2px;
    }

    i {
      font-size: 1.2rem;
      margin-right: 4px;
    }
  }
`;

const Form = styled.form`
  height: 36px;
  margin: 14px 0 36px;
  position: relative;

  input {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 18px;
    color: white;
    font-size: 1.6rem;
    height: 100%;
    padding: 0 40px 0 14px;
    transition: 0.3s;
    width: 100%;

    ::placeholder {
      color: white;
    }

    :focus {
      border-color: white;

      + button {
        color: white;
      }
    }
  }

  button {
    color: rgba(255, 255, 255, 0.2);
    padding: 4px 6px;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;

    :hover {
      color: ${colors.lightPurple};
    }
  }
`;

const Secondary = styled.div`
  h2 {
    background: ${colors.primaryColor};
    font-size: 2rem;
    font-weight: 400;
    height: 54px;
    line-height: 54px;
    padding-left: 40px;
    text-transform: uppercase;
  }

  ul {
    background: ${gradients.purpleToPink("to bottom")};
    list-style: none;
    margin: 0;
    padding: 16px 40px 58px;
  }

  a {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: block;
    font-weight: 500;
    padding: 18px 20px 13px;
    text-transform: uppercase;
    transition: 0.3s;

    :hover {
      border-color: #3ff0a0;
      color: #3ff0a0;
    }
  }
`;

function SearchForm({ closeMenu }) {
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const onSubmit = (data) => {
    closeMenu();
    history.push(`/recherche/${data.search}`);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <input
        aria-label="Rechercher une autre page"
        type="text"
        placeholder="Écrire ici ..."
        name="search"
        ref={register({ required: true })}
      />

      <button type="submit" title="Rechercher">
        <i className="fas fa-search fa-fw" />
      </button>
    </Form>
  );
}

SearchForm.propTypes = {
  closeMenu: PropTypes.func.isRequired,
};

function BurgerMenu({
  isOpen,
  onClick,
  isConnected,
  isTransparent,
  programList,
  categoryList,
  podcastList,
}) {
  return (
    <Container
      isOpen={isOpen}
      isTransparent={isTransparent}
      role="navigation"
      aria-label="Menu déroulant"
    >
      <header>
        <h1>Menu</h1>
        <Burger
          title={isOpen ? "Fermer le menu" : "Ouvrir le menu"}
          onClick={onClick}
          isOpen={isOpen}
          isTransparent={isTransparent}
        >
          <BurgerSqueeze isOpen={isOpen} />
        </Burger>
      </header>

      <Menu>
        <Primary>
          <h2>Le Média 24/7</h2>
          <ul>
            <li>
              <Link to="/direct">
                <i className="far fa-satellite-dish" /> Le Direct
              </Link>
            </li>
          </ul>
          {categoryList && (
            <>
              <h2>Nos articles</h2>
              <ul>
                {categoryList.map((category) => (
                  <li key={category.slug}>
                    <Link to={`/articles/${category.slug}`} onClick={onClick}>
                      <i className="far fa-chevron-right" /> {category.label}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link to="/articles" onClick={onClick}>
                    <i className="far fa-chevron-right" /> Tous les articles
                  </Link>
                </li>
              </ul>
            </>
          )}
          {programList && (
            <>
              <h2>Nos émissions</h2>
              <ul>
                {programList.map((program) => (
                  <li key={program.slug}>
                    <Link to={`/emissions/${program.slug}`} onClick={onClick}>
                      <i className="far fa-chevron-right" /> {program.label}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link to="/emissions" onClick={onClick}>
                    <i className="far fa-chevron-right" /> Toutes les émissions
                  </Link>
                </li>
              </ul>
            </>
          )}
          {podcastList && (
            <>
              <h2>Nos podcasts</h2>
              <ul>
                {podcastList.map((podcast) => (
                  <li key={podcast.slug}>
                    <Link to={`/podcasts/${podcast.slug}`} onClick={onClick}>
                      <i className="far fa-chevron-right" /> {podcast.label}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link to="/podcasts" onClick={onClick}>
                    <i className="far fa-chevron-right" /> Tous les podcasts
                  </Link>
                </li>
              </ul>
            </>
          )}
          <h2>Rechercher</h2>
          <SearchForm closeMenu={onClick} />
        </Primary>
        <Secondary>
          <h2>Le Média c'est aussi ...</h2>
          <ul>
            <li>
              <Link to="/soutien" onClick={onClick}>
                Soutenez-nous !
              </Link>
            </li>
            <li>
              {isConnected ? (
                <Link to="/compte" onClick={onClick}>
                  Mon compte
                </Link>
              ) : (
                <Link to="/connexion" onClick={onClick}>
                  Se connecter
                </Link>
              )}
            </li>
            <li>
              <a href="https://lemediatv.fr/communaute/forum">Le forum</a>
            </li>
            <li>
              <a href="https://video.lemediatv.fr/c/chaine_officielle/videos">
                Peertube
              </a>
            </li>
            <li>
              <Link to="/questions-recurrentes" onClick={onClick}>
                Questions récurrentes
              </Link>
            </li>
            <li>
              <Link to="/annexes/manifeste" onClick={onClick}>
                À propos
              </Link>
            </li>
          </ul>
        </Secondary>
      </Menu>
    </Container>
  );
}

BurgerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isTransparent: PropTypes.bool,
  programList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired
  ),
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired
  ),
  podcastList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired
  ),
};

export default connect((state) => ({
  programList: getProgramList(state),
  categoryList: getCategoryList(state),
  podcastList: getPodcastList(state),
}))(BurgerMenu);
