import { createActions, handleActions } from "redux-actions";
import { normalize } from "normalizr";

import { getData } from "../utilities";
import { story, storeStories } from "./stories";

/*
 *** ACTION CREATORS
 */

const actions = createActions({
  STORIES_PAGE: {
    REQUEST: [(payload) => payload, (_, page) => ({ page })],
    RESPONSE: [
      (payload) => payload,
      (_, page, nbrPages) => ({ page, nbrPages }),
    ],
  },
});

/*
 *** THUNKS
 */

export const fetchStoriesPage =
  (page = 1) =>
  (dispatch) => {
    dispatch(actions.storiesPage.request(undefined, page));

    return getData("/stories/story/", {
      page,
      per_page: 12,
    })
      .then(async (res) => {
        if (res.status === 404) {
          return dispatch(
            actions.storiesPage.response(new Error(res.status), page)
          );
        }

        if (res.status !== 200) {
          return dispatch(
            actions.storiesPage.response(new Error(res.statusText), page)
          );
        }

        const results = (await res.json()).results;
        const stories = normalize(results, [story]);

        dispatch(storeStories(stories.entities.stories));

        return dispatch(
          actions.storiesPage.response(
            stories.result,
            page,
            parseInt(res.headers.get("x-pagination-pages"), 10)
          )
        );
      })
      .catch((error) => dispatch(actions.storiesPage.response(error, page)));
  };

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      actions.storiesPage.request,
      (state, { meta }) => ({
        ...state,
        pages: {
          ...state.pages,
          [meta.page]: { isFetching: true, error: null },
        },
      }),
    ],
    [
      actions.storiesPage.response,
      (state, { error, payload, meta }) => {
        if (error) {
          return {
            ...state,
            pages: {
              ...state.pages,
              [meta.page]: {
                isFetching: false,
                error: payload.message,
              },
            },
          };
        }

        return {
          pages: {
            ...state.pages,
            [meta.page]: { isFetching: false, error: null, stories: payload },
          },
          nbrPages: meta.nbrPages,
        };
      },
    ],
  ]),
  {
    pages: {},
  }
);

/*
 *** SELECTORS
 */

export const getStoriesPage = (state, page, storiesBySlug) => {
  const storyList = state.pages[page];

  if (!storyList) {
    return {
      isFetching: false,
      error: null,
      nbrPages: state.nbrPages,
      stories: [],
    };
  }

  return {
    isFetching: storyList.isFetching,
    error: storyList.error,
    nbrPages: state.nbrPages,
    stories: storyList.stories
      ? storyList.stories.map((slug) => storiesBySlug[slug])
      : [],
  };
};
