import { createActions, handleActions } from "redux-actions";
import { normalize } from "normalizr";

import { getData, getSlugId } from "../utilities";
import { author, storeAuthors } from "./authors";
import { story, storeStories } from "./stories";

/*
 *** ACTION CREATORS
 */

const actions = createActions({
  AUTHOR_PAGE: {
    REQUEST: [payload => payload, (_, slug) => ({ slug })],
    RESPONSE: [payload => payload, (_, slug) => ({ slug })]
  },
  AUTHOR_CONTENT: {
    REQUEST: [
      payload => payload,
      (_, slug, contentType, page) => ({ slug, contentType, page })
    ],
    RESPONSE: [
      payload => payload,
      (_, slug, contentType, page, nbrPages) => ({
        slug,
        contentType,
        page,
        nbrPages
      })
    ]
  }
});

/*
 *** THUNKS
 */

export const fetchAuthorPage = slug => dispatch => {
  dispatch(actions.authorPage.request(undefined, slug));

  return getData(`/authors/by-slug/${slug}/`)
    .then(res => {
      if (res.status === 404) {
        return dispatch(
          actions.authorPage.response(new Error(res.status), slug)
        );
      }

      if (res.status !== 200) {
        return dispatch(
          actions.authorPage.response(new Error(res.statusText), slug)
        );
      }

      return Promise.all([
        dispatch(fetchAuthorContent(slug, "videos")),
        dispatch(fetchAuthorContent(slug, "stories")),
        dispatch(fetchAuthorContent(slug, "audios"))
      ]).then(async () => {
        const results = (await res.json()).results;

        dispatch(storeAuthors(normalize(results, author).entities.authors));

        return dispatch(actions.authorPage.response(undefined, slug));
      });
    })
    .catch(error => dispatch(actions.authorPage.response(error, slug)));
};

export const fetchAuthorContent = (slug, contentType, page = 1) => dispatch => {
  dispatch(actions.authorContent.request(undefined, slug, contentType, page));

  let schemes;
  switch (contentType) {
    case "stories":
      schemes = "STORY";
      break;
    case "audios":
      schemes = "AUDIO,VIDEO_AUDIO";
      break;
    default:
      schemes = "VIDEO,VIDEO_AUDIO";
  }

  return getData(`/stories/`, {
    schemes,
    author_slug: slug,
    page,
    per_page: 12
  })
    .then(async res => {
      if (res.status === 404) {
        return dispatch(
          actions.authorContent.response(
            new Error(res.status),
            slug,
            contentType,
            page
          )
        );
      }

      if (res.status !== 200) {
        return dispatch(
          actions.authorContent.response(
            new Error(res.statusText),
            slug,
            contentType,
            page
          )
        );
      }

      const results = (await res.json()).results;

      const content = normalize(results, [story]);
      dispatch(storeStories(content.entities.stories));

      return dispatch(
        actions.authorContent.response(
          content.result,
          slug,
          contentType,
          page,
          parseInt(res.headers.get("x-pagination-pages"), 10)
        )
      );
    })
    .catch(error =>
      dispatch(
        actions.authorContent.response(
          new Error(error),
          slug,
          contentType,
          page
        )
      )
    );
};

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    /*
     *** AUTHOR_PAGE
     */
    [
      actions.authorPage.request,
      (state, { meta }) => ({
        ...state,
        [getSlugId(meta.slug)]: { isFetching: true, error: null }
      })
    ],
    [
      actions.authorPage.response,
      (state, { error, payload, meta }) => {
        if (error) {
          return {
            ...state,
            [getSlugId(meta.slug)]: {
              isFetching: false,
              error: payload.message
            }
          };
        }

        return {
          ...state,
          [getSlugId(meta.slug)]: {
            ...state[getSlugId(meta.slug)],
            isFetching: false,
            error: null,
            isLoaded: true
          }
        };
      }
    ],

    /*
     *** AUTHOR_CONTENT
     */
    [
      actions.authorContent.request,
      (state, { meta }) => ({
        ...state,
        [getSlugId(meta.slug)]: {
          ...state[getSlugId(meta.slug)],
          [meta.contentType]: {
            ...(state[getSlugId(meta.slug)]
              ? state[getSlugId(meta.slug)][meta.contentType]
              : {}),
            [meta.page]: { isFetching: true, error: null }
          }
        }
      })
    ],
    [
      actions.authorContent.response,
      (state, { error, payload, meta }) => {
        if (error) {
          if (meta.page === 1 && payload.message === "404") {
            return {
              ...state,
              [getSlugId(meta.slug)]: {
                ...state[getSlugId(meta.slug)],
                [meta.contentType]: null
              }
            };
          }

          return {
            ...state,
            [getSlugId(meta.slug)]: {
              ...state[getSlugId(meta.slug)],
              [meta.contentType]: {
                ...state[getSlugId(meta.slug)][meta.contentType],
                [meta.page]: { isFetching: false, error: payload.message }
              }
            }
          };
        }

        return {
          ...state,
          [getSlugId(meta.slug)]: {
            ...state[getSlugId(meta.slug)],
            [meta.contentType]: {
              ...state[getSlugId(meta.slug)][meta.contentType],
              [meta.page]: {
                isFetching: false,
                error: null,
                content: payload
              },
              nbrPages: meta.nbrPages
            }
          }
        };
      }
    ]
  ]),
  {}
);

/*
 *** SELECTORS
 */

const getPrimaryTab = authorPage => {
  const types = [
    { tab: "emissions", key: "videos" },
    { tab: "articles", key: "stories" },
    { tab: "podcasts", key: "audios" }
  ];

  const notEmptyTypes = [];

  for (const type of types) {
    if (authorPage[type.key]) {
      notEmptyTypes.push(type);
    }
  }

  if (notEmptyTypes.length > 1) {
    notEmptyTypes.sort((a, b) => {
      if (authorPage[a.key].nbrPages !== authorPage[b.key].nbrPages) {
        return authorPage[b.key].nbrPages - authorPage[a.key].nbrPages;
      }

      return (
        authorPage[b.key][1].content.length -
        authorPage[a.key][1].content.length
      );
    });
  }

  if (notEmptyTypes.length) {
    return notEmptyTypes[0].tab;
  }
};

export const getAuthorPage = (state, slug, authorsBySlug) => {
  const slugId = getSlugId(slug);
  const authorPage = state[slugId];

  if (!authorPage) {
    return {
      isFetching: false,
      error: null,
      isLoaded: false
    };
  }

  return {
    isFetching: authorPage.isFetching,
    error: authorPage.error,
    isLoaded: authorPage.isLoaded || false,
    author: authorPage.isLoaded && authorsBySlug[slugId],
    haveVideos: authorPage.videos && true,
    haveStories: authorPage.stories && true,
    haveAudios: authorPage.audios && true,
    primaryTab: authorPage.isLoaded && getPrimaryTab(authorPage)
  };
};

export const getAuthorContentList = (
  state,
  slug,
  contentType,
  page,
  storiesBySlug
) => {
  const slugId = getSlugId(slug);
  const authorContent = state[slugId] && state[slugId][contentType];
  const contentList = authorContent && authorContent[page];

  if (!contentList) {
    return {
      isFetching: false,
      error: null,
      nbrPages: authorContent && authorContent.nbrPages,
      content: []
    };
  }

  return {
    isFetching: contentList.isFetching,
    error: contentList.error,
    nbrPages: authorContent.nbrPages,
    content: contentList.content
      ? contentList.content.map(slug => storiesBySlug[slug])
      : []
  };
};
