import { createAction, handleActions } from "redux-actions";
import { schema } from "normalizr";

import { getSlugId, mergeBySlug } from "../utilities";

/*
 *** NORMALIZR
 */

export const story = new schema.Entity("stories", undefined, {
  idAttribute: (story) => getSlugId(story.slug),
});

/*
 *** ACTION CREATORS
 */

export const storeStories = createAction("STORE_STORIES");

export const resetStories = createAction("RESET_STORIES");

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      storeStories,
      (state, { payload }) => ({
        ...state,
        ...mergeBySlug(state, payload),
      }),
    ],
  ]),
  {}
);
