import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import styled from "styled-components";

import { Button } from "../theme";

const Container = styled.div`
  text-align: center;

  h1 {
    font-weight: 600;
    margin: 30px 0;
    text-transform: uppercase;
  }

  p {
    margin: 0 auto 20px;
    width: 500px;
  }
`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtra("errorInfo", errorInfo);
      scope.setExtra("error", error);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <Container>
          <h1>Une erreur est survenue !</h1>
          <p>
            Oups, vous venez de rencontrer une erreur ! N'hésitez pas à nous
            envoyer un rapport pour nous expliquer ce qui s'est passé.
          </p>
          <Button
            onClick={() =>
              Sentry.showReportDialog({
                eventId: this.state.eventId,
                title: "Une erreur est survenue !",
              })
            }
          >
            Je fais un rapport
          </Button>
        </Container>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
