import { combineReducers, createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import stories from "./stories";
import topics from "./topics";
import authors from "./authors";
import menuList, * as fromMenuList from "./menuList";
import homePage, * as fromHomePage from "./homePage";
import videosPage, * as fromVideosPage from "./videosPage";
import videoPage, * as fromVideoPage from "./videoPage";
import storiesPage, * as fromStoriesPage from "./storiesPage";
import storyPage, * as fromStoryPage from "./storyPage";
import audiosPage, * as fromAudiosPage from "./audiosPage";
import audioPage, * as fromAudioPage from "./audioPage";
import topicsPage, * as fromTopicsPage from "./topicsPage";
import topicPage, * as fromTopicPage from "./topicPage";
import authorPage, * as fromAuthorPage from "./authorPage";
import tagPage, * as fromTagPage from "./tagPage";
import podcastPage, * as fromPodcastPage from "./podcastPage";
import searchPage, * as fromSearchPage from "./searchPage";
import user, * as fromUser from "./user";
import resetPassword, * as fromResetPassword from "./resetPassword";
import subscriptionPlans from "./subscriptionPlans";
import order from "./order";
import paymentMethod from "./paymentMethod";

const rootReducer = combineReducers({
  menuList,
  subscriptionPlans,
  homePage,
  videosPage,
  videoPage,
  storiesPage,
  storyPage,
  audiosPage,
  audioPage,
  topicsPage,
  topicPage,
  authorPage,
  tagPage,
  podcastPage,
  searchPage,
  user,
  resetPassword,
  order,
  paymentMethod,
  stories,
  topics,
  authors,
});

/*
 ***  SELECTORS
 */

export const getProgramList = (state) =>
  fromMenuList.getProgramList(state.menuList);

export const getCategoryList = (state) =>
  fromMenuList.getCategoryList(state.menuList);

export const getPodcastList = (state) =>
  fromMenuList.getPodcastList(state.menuList);

export const getHomePage = (state) =>
  fromHomePage.getHomePage(state.homePage, state.stories);

export const getVideosPage = (state, page = 1) =>
  fromVideosPage.getVideosPage(state.videosPage, page, state.stories);

export const getVideoPage = (state, slug) =>
  fromVideoPage.getVideoPage(state.videoPage, slug, state.stories);

export const getStoriesPage = (state, page = 1) =>
  fromStoriesPage.getStoriesPage(state.storiesPage, page, state.stories);

export const getStoryPage = (state, slug) =>
  fromStoryPage.getStoryPage(state.storyPage, slug, state.stories);

export const getAudiosPage = (state, page = 1) =>
  fromAudiosPage.getAudiosPage(state.audiosPage, page, state.stories);

export const getLastAudio = (state) =>
  fromAudiosPage.getLastAudio(state.audiosPage, state.stories);

export const getAudioPage = (state, slug) =>
  fromAudioPage.getAudioPage(state.audioPage, slug, state.stories);

export const getTopicsPage = (state, page = 1) =>
  fromTopicsPage.getTopicsPage(state.topicsPage, page, state.topics);

export const getTopicPage = (state, slug) =>
  fromTopicPage.getTopicPage(state.topicPage, slug, state.topics);

export const getAuthorPage = (state, slug) =>
  fromAuthorPage.getAuthorPage(state.authorPage, slug, state.authors);

export const getAuthorContentList = (state, slug, contentType, page = 1) =>
  fromAuthorPage.getAuthorContentList(
    state.authorPage,
    slug,
    contentType,
    page,
    state.stories
  );

export const getTagPage = (state, slug, page = 1) =>
  fromTagPage.getTagPage(state.tagPage, slug, page, state.stories);

export const getPodcastPage = (state, slug, page = 1) =>
  fromPodcastPage.getPodcastPage(state.podcastPage, slug, page, state.stories);

export const getSearchPage = (state, query, page = 1) =>
  fromSearchPage.getSearchPage(state.searchPage, query, page);

export const getSubscriptionPlans = (state) => state.subscriptionPlans;

export const getAccount = (state) => fromUser.getAccount(state.user);
export const getAuth = (state) => fromUser.getAuth(state.user);
export const getSubscription = (state) => fromUser.getSubscription(state.user);

export const getRequestResetPassword = (state) =>
  fromResetPassword.getRequestResetPassword(state.resetPassword);
export const getMakeResetPassword = (state) =>
  fromResetPassword.getMakeResetPassword(state.resetPassword);

export const getOrder = (state) => state.order;

export const getPaymentMethod = (state) => state.paymentMethod;
/*
 *** STORE
 */

export default function configureStore(preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  );
}
