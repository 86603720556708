import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Img from "react-image";

import {
  getProgramList,
  getCategoryList,
  getLastAudio,
} from "../../configureStore";
import { getPodcastLink } from "../../utilities";
import {
  colors,
  gradients,
  GridWrapper,
  useMinTabletLandscape,
  useMobile,
} from "../../theme";
import Newsletter from "../Newsletter";
import bgMLetter from "../../assets/bg-mletter-footer.svg";
import logo from "../../assets/logo-lemedia-white.svg";
import podcastPlaceholder from "../../assets/logo-lemedia-red.png";
import btnPodcast from "../../assets/btn-podcast-red.svg";
import bgSuscribeLeft from "../../assets/bg-darkgrey-top-left.png";
import bgSuscribeRight from "../../assets/bg-darkgrey-top-right.png";
import bgSocial from "../../assets/bg-purple-top.png";

const MLetter = styled.div`
  background: url(${bgMLetter}) center 40px no-repeat;
  height: 192px;
`;

const Subscribe = styled.div`
  background: url(${bgSuscribeLeft}) left top no-repeat,
    url(${bgSuscribeRight}) right bottom no-repeat,
    ${gradients.darkGreyToPink("to left")};
  color: white;
  padding: 35px 0 50px;

  > div {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      display: block;
    }

    > div {
      width: 45%;

      @media (max-width: 767px) {
        width: 100%;
      }

      &:last-of-type {
        width: 45%;

        @media (max-width: 767px) {
          border-top: 2px solid rgba(255, 255, 255, 0.3);
          margin-top: 40px;
          padding-top: 30px;
          width: 100%;
        }
      }
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 0.01rem;
    margin-bottom: 21px;
    text-transform: uppercase;

    @media (max-width: 1023px) {
      margin-bottom: 15px;
    }

    strong {
      display: block;
      font-size: 6.4rem;
      font-weight: 700;
      letter-spacing: 0.05rem;
      line-height: 6.1rem;

      @media (max-width: 1023px) {
        font-size: 4rem;
        line-height: 4rem;
      }
    }
  }

  p {
    letter-spacing: -0.012rem;
    line-height: 2.4rem;

    &.already-subscribed {
      background: rgba(243, 243, 243, 0.2);
      font-weight: 600;
      margin-top: 23px;
      padding: 10px;
      text-align: center;

      i {
        margin-right: 6px;
      }
    }
  }

  form {
    margin-top: 23px;

    > div {
      display: flex;
      justify-content: space-between;

      @media (max-width: 1023px) {
        display: block;
      }
    }

    p {
      color: ${colors.darkBlue};
    }
  }

  input {
    background: rgba(243, 243, 243, 0.2);
    border: 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    color: white;
    font-size: 2rem;
    height: 44px;
    opacity: 0.6;
    padding: 0 17px;
    transition: 0.3s;
    width: 80%;

    @media (max-width: 1023px) {
      margin-bottom: 15px;
      width: 100%;
    }

    :hover:not(:disabled),
    :focus:not(:disabled) {
      opacity: 1;
    }

    ::placeholder {
      color: rgba(255, 255, 255, 0.9);
    }
  }

  button {
    background: black;
    color: white;
    font-weight: 600;
    height: 44px;
    text-transform: uppercase;
    transition: 0.3s;
    width: 72px;

    @media (max-width: 1023px) {
      display: block;
      margin: 0 0 0 auto;
    }

    :hover {
      background: rgba(0, 0, 0, 0.75);
    }
  }
`;

const Podcast = styled(Link)`
  align-items: flex-start;
  color: white;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  text-decoration: none;

  :hover > div:first-of-type {
    :before {
      opacity: 1;
    }

    :after {
      transform: translate(-28px, -28px) scale(1);

      @media (max-width: 1599px) {
        transform: translate(-22px, -22px) scale(1);
      }
    }
  }

  @media (max-width: 1023px) {
    margin: 0;
  }

  > div:last-of-type {
    width: calc(100% - 142px);

    @media (max-width: 1599px) {
      width: calc(100% - 106px);
    }

    @media (max-width: 1023px) {
      width: 100%;
    }
  }

  h3 {
    font-size: 2rem;
    font-weight: 500;
    margin: 0 0 10px;
    text-transform: uppercase;
    transition: 0.3s;

    @media (max-width: 1023px) {
      font-size: 1.7rem;
      line-height: 2.15rem;
    }
  }
`;

const PodcastImg = styled.div`
  overflow: hidden;
  position: relative;
  width: 114px;

  :before {
    background: rgba(16, 0, 43, 0.45);
    border-radius: 8px;
    content: "";
    display: block;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: 0.3s;
    width: 100%;
  }

  :after {
    background: url(${btnPodcast}) center no-repeat white;
    background-size: 18px;
    border-radius: 50%;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    content: "";
    display: block;
    height: 56px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-28px, -28px) scale(0);
    transition: 0.3s;
    width: 56px;
  }

  @media (max-width: 1599px) {
    width: 88px;

    :after {
      background-size: 16px;
      height: 44px;
      transform: translate(-22px, -22px) scale(0);
      width: 44px;
    }
  }

  img {
    border-radius: 8px;
    display: block;
    width: 100%;
  }
`;

const Social = styled.div`
  background: url(${bgSocial}) right bottom no-repeat,
    ${gradients.redToPurple()};
  padding: 24px 0;

  @media (max-width: 767px) {
    padding-top: 34px;
  }

  > div {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      display: block;
    }
  }

  nav {
    color: white;
    font-size: 1.4rem;
    letter-spacing: 0.04rem;
    padding-top: 2px;
    text-align: right;
    text-transform: uppercase;

    @media (max-width: 1023px) {
      letter-spacing: 0rem;
    }

    @media (max-width: 767px) {
      text-align: center;
    }

    a {
      color: white;
      display: inline-block;
      font-size: 4.4rem;
      margin: 4px 0 0 34px;
      transition: 0.3s;

      @media (max-width: 1023px) {
        font-size: 3.2rem;
      }

      @media (max-width: 767px) {
        margin: 15px 17px 0;
        text-align: center;
      }

      :hover {
        color: ${colors.green};
      }
    }

    span {
      display: inline-block;
      text-align: left;
      text-indent: -9999px;
    }
  }
`;

const Logo = styled(Link)`
  background: url(${logo}) no-repeat center;
  background-size: cover;
  display: block;
  height: 67px;
  text-indent: -9999px;
  width: 316px;

  @media (max-width: 1023px) {
    height: 57px;
    width: 268px;
  }

  @media (max-width: 767px) {
    margin: 0 auto 25px;
  }
`;

const Summary = styled(GridWrapper)`
  display: flex;
  justify-content: space-between;
  padding: 64px 0 29px;

  @media (max-width: 767px) {
    display: block;
    padding-top: 32px;
  }

  > div {
    width: 184px;

    :first-of-type {
      padding-right: 60px;
      width: 442px;

      @media (max-width: 1023px) {
        padding-right: 30px;
      }

      @media (max-width: 767px) {
        padding: 0;
        width: 100% !important;
      }
    }

    :last-of-type {
      width: 126px;
    }

    @media (max-width: 767px) {
      padding: 0 !important;
      width: 100% !important;
    }
  }

  h2 {
    color: ${colors.gray};
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 2.8rem;
  }

  p {
    line-height: 2.4rem;
    margin: 25px 0;

    a {
      color: ${colors.primaryColor};
      font-weight: 600;
      text-decoration: none;
      transition: 0.3s;

      :hover {
        text-decoration: underline;
      }
    }
  }

  nav {
    margin-top: 18px;

    a {
      color: ${colors.textColor};
      display: block;
      line-height: 2.4rem;
      text-decoration: none;
      transition: 0.3s;

      :hover {
        color: ${colors.primaryColor};
      }
    }
  }
`;

const Copyright = styled.div`
  border-top: 1px solid #cbd2d9;
  color: black;
  font-size: 1.4rem;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
  padding: 26px 0;

  > div {
    display: flex;
    justify-content: space-between;
  }

  a {
    color: black;
    display: inline-block;
    margin-left: 40px;
    text-decoration: none;
    transition: 0.3s;

    :hover {
      color: ${colors.primaryColor};
    }
  }
`;

function Footer({
  innerRef,
  isConnected,
  isLetterHidden,
  isNewsletterHidden,
  programList,
  categoryList,
  lastAudio,
}) {
  const isMinTabletLandscape = useMinTabletLandscape();
  const isMobile = useMobile();

  // const lastAudio = {
  //   audio: {
  //     title: "Franquisme, chroniques d'une impunité",
  //     headline_or_extract_medium:
  //       "Notre podcast sur la lutte contre l’impunité des criminels franquistes est désormais disponible en un unique épisode de 50 minutes. Retrouvez-le sur toutes les plateformes.",
  //     canonical_url:
  //       "https://www.lemediatv.fr/podcasts/2021/franquisme-chroniques-dune-impunite-lintegrale-un-podcast-le-media-tv-P1gzOxzARryB5HCQTrs3vQ",
  //     thumb_podcast_footer_url:
  //       "https://static.lemediatv.fr/stories/2021/thumb_podcast_footer-franquisme-chroniques-dune-impunite-lintegrale-un-podcast-le-media-tv-P1gzOxzARryB5HCQTrs3vQ.jpg",
  //   },
  // };

  return (
    <footer ref={innerRef} id="inscription-newsletter">
      {!isLetterHidden && <MLetter />}
      {!isNewsletterHidden && (
        <Subscribe>
          <GridWrapper>
            <div>
              <h2>
                Inscrivez-vous à notre <strong>newsletter</strong>
              </h2>
              <p>
                Pour recevoir les dernières actualités, le résumé de la semaine
                et être alerté des derniers programmes publiés directement dans
                votre boîte e-mail.
              </p>
              <Newsletter />
            </div>
            {lastAudio.audio && (
              <div>
                <h2>
                  Retrouvez notre dernier <strong>podcast</strong>
                </h2>
                <Podcast to={getPodcastLink(lastAudio.audio.canonical_url)}>
                  {isMinTabletLandscape && (
                    <PodcastImg>
                      <Img
                        src={lastAudio.audio.thumb_podcast_footer_url}
                        alt={lastAudio.audio.title}
                        loader={
                          <img
                            src={podcastPlaceholder}
                            alt={lastAudio.audio.title}
                          />
                        }
                        unloader={
                          <img
                            src={podcastPlaceholder}
                            alt={lastAudio.audio.title}
                          />
                        }
                      />
                    </PodcastImg>
                  )}
                  <div>
                    <h3>{lastAudio.audio.title}</h3>
                    <p>{lastAudio.audio.headline_or_extract_medium}</p>
                  </div>
                </Podcast>
              </div>
            )}
          </GridWrapper>
        </Subscribe>
      )}
      <Social>
        <GridWrapper>
          <Logo to="/">Le Média</Logo>
          <nav role="navigation" aria-label="Menu - Nos réseaux sociaux">
            Retrouvez-nous sur nos réseaux sociaux :
            <div>
              <a
                href="https://www.facebook.com/LeMediaOfficiel/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook" />
                <span>Facebook</span>
              </a>
              <a
                href="https://twitter.com/LeMediaTV"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter" />
                <span>Twitter</span>
              </a>
              <a
                href="https://www.youtube.com/channel/UCT67YOMntJxfRnO_9bXDpvw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube" />
                <span>Youtube</span>
              </a>
            </div>
          </nav>
        </GridWrapper>
      </Social>
      <Summary>
        <div>
          <h2>Le Média n’appartient ni à l’Etat, ni à des milliardaires.</h2>
          <p>
            Il est financé uniquement par des citoyens comme vous. Il est en
            accès libre pour le plus grand nombre grâce à vous. Sans vous, Le
            Média n’existerait pas.
          </p>
          <p>
            Engagé en faveur des causes sociales et écologistes, Le Média, de
            par son libre accès, s'efforce de remplir la mission de service
            public d'information aujourd'hui en voie de disparition dans le
            paysage médiatique.
          </p>
          <p>
            Soutenez et rejoignez le projet en{" "}
            <Link to="/soutien">devenant Socio</Link>.
          </p>
        </div>
        <>
          {isMinTabletLandscape && (
            <>
              <div>
                <h2>Les émissions</h2>
                {programList && (
                  <nav role="navigation" aria-label="Menu - Nos émissions">
                    {programList.map((program) => (
                      <Link
                        to={`/emissions/${program.slug}`}
                        key={program.slug}
                      >
                        {program.label}
                      </Link>
                    ))}
                  </nav>
                )}
              </div>
              <div>
                <h2>Les articles</h2>
                {categoryList && (
                  <nav role="navigation" aria-label="Menu - Nos articles">
                    {categoryList.map((category) => (
                      <Link key={category.slug} to={`/tags/${category.slug}`}>
                        {category.label}
                      </Link>
                    ))}
                  </nav>
                )}
              </div>
            </>
          )}
          <div>
            <h2>À propos</h2>
            <nav role="navigation" aria-label="Menu - Nos valeurs">
              <Link to="/annexes/manifeste">Manifeste</Link>
              <Link to="/annexes/statuts">Statuts</Link>
              <Link to="/annexes/reglement-interieur">Règlement intérieur</Link>
              <Link to="/questions-recurrentes">Questions récurrentes</Link>
              <Link to="/annexes/cgu">CGU</Link>
              <Link to="/recrutement">Recrutement</Link>
              <a href="mailto:contact@lemediatv.fr">Contact</a>
            </nav>
          </div>
          {!isMobile && (
            <div>
              <h2>Espace des&nbsp;Socios</h2>
              <nav role="navigation" aria-label="Menu - L'espace des Socios">
                <Link to="/soutien">Soutenir Le Média</Link>
                {isConnected ? (
                  <Link to="/compte">Mon compte</Link>
                ) : (
                  <Link to="/connexion">Se connecter</Link>
                )}
                <a href="https://lemediatv.fr/communaute/forum">Le forum</a>
              </nav>
            </div>
          )}
        </>
      </Summary>
      <Copyright>
        <GridWrapper>
          © Le Média 2017
          <nav role="navigation" aria-label="Menu - Éléments légaux">
            <Link to="/annexes/mentions-legales">Mentions légales</Link>
          </nav>
        </GridWrapper>
      </Copyright>
    </footer>
  );
}

Footer.propTypes = {
  innerRef: PropTypes.any,
  isConnected: PropTypes.bool.isRequired,
  isLetterHidden: PropTypes.bool.isRequired,
  isNewsletterHidden: PropTypes.bool.isRequired,
  programList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired
  ),
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired
  ),
  lastAudio: PropTypes.shape({
    audio: PropTypes.shape({
      title: PropTypes.string.isRequired,
      headline_or_extract_medium: PropTypes.string.isRequired,
      canonical_url: PropTypes.string.isRequired,
      thumb_podcast_footer_url: PropTypes.string,
    }),
  }).isRequired,
};

export default connect((state) => ({
  programList: getProgramList(state),
  categoryList: getCategoryList(state),
  lastAudio: getLastAudio(state),
}))(Footer);
