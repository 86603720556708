import { createActions, handleActions } from "redux-actions";
import * as Sentry from "@sentry/browser";

import { fetchShopData } from "../utilities";
import { actions as userActions, fetchAccount } from "./user";

/*
 *** ACTION CREATORS
 */

const actions = createActions({
  ORDER: {
    REQUEST: undefined,
    RESPONSE: undefined,
  },
});

export const makeOrder = (items, paymentMethod, stripeConfirmation) => async (
  dispatch
) => {
  dispatch(actions.order.request());

  try {
    // Get current cart
    const fetchRes = await fetchShopData("/account/orders/cart/");
    if (fetchRes.status !== 200) {
      return dispatch(actions.order.response(new Error(fetchRes.statusText)));
    }
    const currentOrder = (await fetchRes.json()).results;

    // Cancel current cart if not pristine
    if (
      currentOrder.active_items.length !== 0 ||
      currentOrder.status !== "PENDING"
    ) {
      const resetRes = await fetchShopData(
        `/account/orders/cart/state/cancel/`,
        {
          method: "PUT",
        }
      );
      if (resetRes.status !== 200) {
        return dispatch(actions.order.response(new Error(resetRes.statusText)));
      }
    }

    // Add items in cart
    for (let item of items) {
      Sentry.setContext("request", item);
      const addItemRes = await fetchShopData("/account/orders/cart/items/", {
        method: "POST",
        body: JSON.stringify(item),
      });
      if (addItemRes.status !== 200) {
        return dispatch(
          actions.order.response(new Error(addItemRes.statusText))
        );
      }
    }

    // Confirm cart before payment
    const confirmCartRes = await fetchShopData(
      `/account/orders/cart/state/payment/`,
      {
        method: "PUT",
      }
    );
    if (confirmCartRes.status !== 200) {
      return dispatch(
        actions.order.response(new Error(confirmCartRes.statusText))
      );
    }

    // Register payment with check
    if (paymentMethod === "CHECK") {
      const checkPaymentRes = await fetchShopData(
        `/account/orders/cart/payment/DIRECT_PAYMENT/CHECK/`,
        { method: "POST" }
      );

      if (checkPaymentRes.status !== 200) {
        return dispatch(
          actions.order.response(new Error(checkPaymentRes.statusText))
        );
      }

      await dispatch(fetchAccount());
      const order = (await confirmCartRes.json()).results;
      const results = await checkPaymentRes.json();
      return dispatch(
        actions.order.response({
          ...order,
          payment_method: "CHECK",
          status: results.statuts,
        })
      );
    }

    // Create payment intent and get client secret with stripe
    const intentPaymentRes = await fetchShopData(
      `/account/orders/cart/payment/stripe/${paymentMethod}/`,
      { method: "POST" }
    );
    if (intentPaymentRes.status !== 200) {
      return dispatch(
        actions.order.response(new Error(intentPaymentRes.statusText))
      );
    }
    const clientSecret = (await intentPaymentRes.json()).clientSecret;

    // Make stripe payment
    const stripeConfirmationRes = await stripeConfirmation(clientSecret);
    if (stripeConfirmationRes.error) {
      return dispatch(
        actions.order.response(new Error(stripeConfirmationRes.error.message))
      );
    }

    // Validate payment
    const validationRes = await fetchShopData(
      "/account/orders/cart/validate/",
      {
        method: "PUT",
      }
    );
    if (validationRes.status !== 200) {
      return dispatch(
        actions.order.response(new Error(validationRes.statusText))
      );
    }

    const results = (await validationRes.json()).results;
    await dispatch(fetchAccount());
    return dispatch(actions.order.response(results));
  } catch (error) {
    return dispatch(actions.order.response(error));
  }
};

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      actions.order.request,
      (state) => ({
        ...state,
        isFetching: true,
        error: null,
      }),
    ],
    [
      actions.order.response,
      (state, { error, payload }) => {
        if (error) {
          Sentry.captureException(payload);

          return {
            ...state,
            isFetching: false,
            error: payload.message,
          };
        }

        return {
          ...state,
          isFetching: false,
          error: null,
          cart: payload,
        };
      },
    ],
    [userActions.user.logout, () => ({ isFetching: false, error: null })],
  ]),
  { isFetching: false, error: null }
);
