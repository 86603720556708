import { createActions, handleActions } from "redux-actions";
import { normalize } from "normalizr";

import { getData, getSlugId } from "../utilities";
import { story, storeStories } from "./stories";

/*
 *** ACTION CREATORS
 */

const actions = createActions({
  PODCAST_PAGE: {
    REQUEST: [payload => payload, (_, slug, page) => ({ slug, page })],
    RESPONSE: [
      payload => payload,
      (_, slug, page, podcast, nbrPages) => ({ slug, page, podcast, nbrPages })
    ]
  }
});

export const fetchPodcastPage = (slug, page = 1) => dispatch => {
  dispatch(actions.podcastPage.request(undefined, slug, page));

  return getData("/stories/", {
    tag_slug: slug,
    schemes: "AUDIO,VIDEO_AUDIO",
    page,
    per_page: 12
  })
    .then(async res => {
      if (res.status === 404) {
        return dispatch(
          actions.podcastPage.response(new Error(res.status), slug, page)
        );
      }

      if (res.status !== 200) {
        return dispatch(
          actions.podcastPage.response(new Error(res.statusText), slug, page)
        );
      }

      const results = (await res.json()).results;
      const content = normalize(results, [story]);

      dispatch(storeStories(content.entities.stories));

      return dispatch(
        actions.podcastPage.response(
          content.result,
          slug,
          page,
          results[0].primary_category,
          parseInt(res.headers.get("x-pagination-pages"), 10)
        )
      );
    })
    .catch(error => dispatch(actions.podcastPage.response(error, slug, page)));
};
/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      actions.podcastPage.request,
      (state, { meta }) => ({
        ...state,
        [getSlugId(meta.slug)]: {
          ...(state[getSlugId(meta.slug)] ? state[getSlugId(meta.slug)] : {}),
          pages: {
            ...(state[getSlugId(meta.slug)]
              ? state[getSlugId(meta.slug)].pages
              : {}),
            [meta.page]: { isFetching: true, error: null }
          }
        }
      })
    ],
    [
      actions.podcastPage.response,
      (state, { error, payload, meta }) => {
        if (error) {
          return {
            ...state,
            [getSlugId(meta.slug)]: {
              ...state[getSlugId(meta.slug)],
              pages: {
                ...state[getSlugId(meta.slug)].pages,
                [meta.page]: { isFetching: false, error: payload.message }
              }
            }
          };
        }

        return {
          ...state,
          [getSlugId(meta.slug)]: {
            pages: {
              ...state[getSlugId(meta.slug)].pages,
              [meta.page]: { isFetching: false, error: null, content: payload }
            },
            podcast: meta.podcast,
            nbrPages: meta.nbrPages
          }
        };
      }
    ]
  ]),
  {}
);

export const getPodcastPage = (state, slug, page, storiesBySlug) => {
  const slugId = getSlugId(slug);
  const podcastPage = state[slugId] && state[slugId].pages[page];

  if (!podcastPage) {
    return {
      isFetching: false,
      error: null,
      podcast: state[slugId] && state[slugId].podcast,
      nbrPages: state[slugId] && state[slugId].nbrPages,
      content: []
    };
  }

  return {
    isFetching: podcastPage.isFetching,
    error: podcastPage.error,
    podcast: state[slugId].podcast,
    nbrPages: state[slugId].nbrPages,
    content:
      (podcastPage.content &&
        podcastPage.content.map(slug => storiesBySlug[slug])) ||
      []
  };
};
