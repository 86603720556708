import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import { colors } from "../../theme";

const Form = styled.form`
  height: 36px;
  position: relative;

  input {
    background: #f9f7fa;
    border: 0;
    border-bottom: 2px solid #eee9f6;
    color: black;
    font-size: 1.6rem;
    height: 100%;
    padding: 0 40px 0 14px;
    transition: 0.3s;
    width: 100%;

    ::placeholder {
      color: ${colors.lightPurple};
    }

    :focus {
      border-color: ${colors.lightPurple};

      + button {
        color: ${colors.lightPurple};
      }
    }
  }

  button {
    color: #eee9f6;
    padding: 4px 6px;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;

    :hover {
      color: ${colors.lightPurple};
    }
  }
`;

function SearchForm({ inputRef }) {
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const onSubmit = data => history.push(`/recherche/${data.search}`);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <input
        aria-label="Rechercher une autre page"
        type="text"
        placeholder="Rechercher ..."
        name="search"
        ref={e => {
          register(e, { required: true });
          if (inputRef) {
            inputRef.current = e;
          }
        }}
      />

      <button type="submit" title="Rechercher">
        <i className="fas fa-search fa-fw" />
      </button>
    </Form>
  );
}

SearchForm.propTypes = {
  inputRef: PropTypes.object
};

export default SearchForm;
