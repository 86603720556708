import { createActions, handleActions } from "redux-actions";
import { normalize } from "normalizr";

import { getData, getSlugId } from "../utilities";
import { story, storeStories, resetStories } from "./stories";

/*
 *** ACTION CREATORS
 */

const actions = createActions({
  AUDIO_PAGE: {
    REQUEST: [(payload) => payload, (_, slug) => ({ slug })],
    RESPONSE: [(payload) => payload, (_, slug) => ({ slug })],
  },
});

/*
 *** THUNKS
 */

export const fetchAudioPage = (slug, previewToken) => (dispatch) => {
  dispatch(actions.audioPage.request(undefined, slug));

  return getData(`/stories/by-slug/${slug}/${previewToken ? previewToken : ""}`)
    .then(async (res) => {
      if (res.status === 404) {
        return dispatch(
          actions.audioPage.response(new Error(res.status), slug)
        );
      }

      if (res.status !== 200) {
        return dispatch(
          actions.audioPage.response(new Error(res.statusText), slug)
        );
      }

      const results = (await res.json()).results;
      const contextual_stories = normalize(results.contextual_stories, [story]);
      results.contextual_stories = contextual_stories.result;
      const audioPage = normalize(results, story);

      dispatch(
        storeStories({
          ...contextual_stories.entities.stories,
          ...audioPage.entities.stories,
        })
      );

      return dispatch(actions.audioPage.response(undefined, slug));
    })
    .catch((error) => dispatch(actions.audioPage.response(error, slug)));
};

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      actions.audioPage.request,
      (state, { meta }) => ({
        ...state,
        [getSlugId(meta.slug)]: { isFetching: true, error: null },
      }),
    ],
    [
      actions.audioPage.response,
      (state, { error, payload, meta }) => {
        if (error) {
          return {
            ...state,
            [getSlugId(meta.slug)]: {
              isFetching: false,
              error: payload.message,
            },
          };
        }

        return {
          ...state,
          [getSlugId(meta.slug)]: {
            isFetching: false,
            error: null,
            isLoaded: true,
          },
        };
      },
    ],
    [resetStories, () => ({})],
  ]),
  {}
);

/*
 *** SELECTORS
 */

export const getAudioPage = (state, slug, storiesBySlug) => {
  const slugId = getSlugId(slug);
  const audioPage = state[slugId];

  if (!audioPage) {
    return {
      isFetching: false,
      error: null,
      isLoaded: false,
    };
  }

  let audio;
  if (audioPage.isLoaded) {
    audio = { ...storiesBySlug[slugId] };
    audio.contextual_stories = audio.contextual_stories.map(
      (slug) => storiesBySlug[slug]
    );
  }

  return {
    isFetching: audioPage.isFetching,
    error: audioPage.error,
    isLoaded: audioPage.isLoaded || false,
    audio,
  };
};
