import { createActions, handleActions } from "redux-actions";

import { getData } from "../utilities";

/*
 *** ACTION CREATORS
 */

const actions = createActions({
  SEARCH_PAGE: {
    REQUEST: [payload => payload, (_, query, page) => ({ query, page })],
    RESPONSE: [
      payload => payload,
      (_, query, page, nbrPages, total) => ({ query, page, nbrPages, total })
    ]
  }
});

/*
 *** THUNKS
 */

export const fetchSearchPage = (query, page = 1) => dispatch => {
  dispatch(actions.searchPage.request(undefined, query, page));

  return getData("/stories/fulltext/", {
    query: query,
    page,
    per_page: 12
  })
    .then(async res => {
      if (res.status === 404) {
        return dispatch(
          actions.searchPage.response(new Error(res.status), query, page)
        );
      }

      if (res.status !== 200) {
        return dispatch(
          actions.searchPage.response(new Error(res.statusText), query, page)
        );
      }

      const results = (await res.json()).results;

      if (!results.length) {
        return dispatch(actions.searchPage.response(null, query));
      }

      return dispatch(
        actions.searchPage.response(
          results,
          query,
          page,
          parseInt(res.headers.get("x-pagination-pages"), 10),
          parseInt(res.headers.get("x-pagination-total"), 10)
        )
      );
    })
    .catch(error => dispatch(actions.searchPage.response(error, query, page)));
};

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      actions.searchPage.request,
      (state, { meta }) => ({
        ...state,
        [meta.query]: {
          ...state[meta.query],
          pages: {
            ...(state[meta.query] ? state[meta.query].pages : {}),
            [meta.page]: { isFetching: true, error: null }
          }
        }
      })
    ],
    [
      actions.searchPage.response,
      (state, { error, payload, meta }) => {
        if (error) {
          return {
            ...state,
            [meta.query]: {
              ...state[meta.query],
              pages: {
                ...state[meta.query].pages,
                [meta.page]: { isFetching: false, error: payload.message }
              }
            }
          };
        }

        if (payload === null) {
          return {
            ...state,
            [meta.query]: { isEmpty: true }
          };
        }

        return {
          ...state,
          [meta.query]: {
            pages: {
              ...state[meta.query].pages,
              [meta.page]: { isFetching: false, error: null, content: payload }
            },
            nbrPages: meta.nbrPages,
            total: meta.total
          }
        };
      }
    ]
  ]),
  {}
);

/*
 *** SELECTORS
 */

export const getSearchPage = (state, query, page) => {
  if (state[query] && state[query].isEmpty) {
    return { isEmpty: true };
  }

  const searchPage = state[query] && state[query].pages[page];

  if (!searchPage) {
    return {
      isFetching: false,
      error: null,
      nbrPages: state[query] && state[query].nbrPages,
      total: state[query] && state[query].total,
      content: []
    };
  }

  return {
    isFetching: searchPage.isFetching,
    error: searchPage.error,
    nbrPages: state[query].nbrPages,
    total: state[query].total,
    content: searchPage.content || []
  };
};
