import { createActions, handleActions } from "redux-actions";
import { normalize } from "normalizr";

import { getData } from "../utilities";
import { story, storeStories } from "./stories";

/*
 *** ACTION CREATORS
 */

const actions = createActions({
  AUDIOS_PAGE: {
    REQUEST: [(payload) => payload, (_, page) => ({ page })],
    RESPONSE: [
      (payload) => payload,
      (_, page, nbrPages) => ({ page, nbrPages }),
    ],
  },
});

/*
 *** THUNKS
 */

export const fetchAudiosPage = (page = 1) => (dispatch) => {
  dispatch(actions.audiosPage.request(undefined, page));

  return getData("/stories/", {
    schemes: "AUDIO,VIDEO_AUDIO",
    page,
    per_page: 12,
  })
    .then(async (res) => {
      if (res.status === 404) {
        return dispatch(
          actions.audiosPage.response(new Error(res.status), page)
        );
      }

      if (res.status !== 200) {
        return dispatch(
          actions.audiosPage.response(new Error(res.statusText), page)
        );
      }

      const results = (await res.json()).results;
      const audios = normalize(results, [story]);

      dispatch(storeStories(audios.entities.stories));

      return dispatch(
        actions.audiosPage.response(
          audios.result,
          page,
          parseInt(res.headers.get("x-pagination-pages"), 10)
        )
      );
    })
    .catch((error) => dispatch(actions.audiosPage.response(error, page)));
};

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      actions.audiosPage.request,
      (state, { meta }) => ({
        ...state,
        pages: {
          ...state.pages,
          [meta.page]: { isFetching: true, error: null },
        },
      }),
    ],
    [
      actions.audiosPage.response,
      (state, { error, payload, meta }) => {
        if (error) {
          return {
            ...state,
            pages: {
              ...state.pages,
              [meta.page]: {
                isFetching: false,
                error: payload.message,
              },
            },
          };
        }

        return {
          pages: {
            ...state.pages,
            [meta.page]: { isFetching: false, error: null, audios: payload },
          },
          nbrPages: meta.nbrPages,
        };
      },
    ],
  ]),
  {
    pages: {},
  }
);

/*
 *** SELECTORS
 */

export const getAudiosPage = (state, page, storiesBySlug) => {
  const audioList = state.pages[page];

  if (!audioList) {
    return {
      isFetching: false,
      error: null,
      nbrPages: state.nbrPages,
      audios: [],
    };
  }

  return {
    isFetching: audioList.isFetching,
    error: audioList.error,
    nbrPages: state.nbrPages,
    audios: audioList.audios
      ? audioList.audios.map((slug) => storiesBySlug[slug])
      : [],
  };
};

export const getLastAudio = (state, storiesBySlug) => {
  const audioList = state.pages[1];

  if (!audioList) {
    return {
      isFetching: false,
      error: null,
    };
  }

  return {
    isFetching: audioList.isFetching,
    error: audioList.error,
    audio: audioList.audios
      ? audioList.audios.map((slug) => storiesBySlug[slug])[0]
      : undefined,
  };
};
