import { createActions, handleActions } from "redux-actions";

import { getData } from "../utilities";

/*
 *** ACTION CREATORS
 */

const actions = createActions({
  MENU_LIST: {
    REQUEST: undefined,
    RESPONSE: undefined,
  },
});

/*
 *** THUNKS
 */

export const fetchMenuList = () => (dispatch) => {
  dispatch(actions.menuList.request());

  return getData(
    "/configurations/?keys=program_list,category_list,podcast_list"
  )
    .then(async (res) => {
      if (res.status !== 200) {
        return dispatch(actions.menuList.response(new Error(res.statusText)));
      }

      const results = (await res.json()).results;

      return dispatch(
        actions.menuList.response({
          program: results.find((result) => result.key === "program_list")
            .value,
          category: results.find((result) => result.key === "category_list")
            .value,
          podcast: results.find((result) => result.key === "podcast_list")
            .value,
        })
      );
    })
    .catch((error) => dispatch(actions.menuList.response(error)));
};

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [actions.menuList.request, () => ({ isFetching: true, error: null })],
    [
      actions.menuList.response,
      (_, { error, payload }) => {
        if (error) {
          return { isFetching: false, error: payload.message };
        }

        return { isFetching: false, error: null, menuList: payload };
      },
    ],
  ]),
  { isFetching: true, error: null }
);

/*
 *** SELECTORS
 */

export const getProgramList = (state) => {
  if (!state.menuList) {
    return null;
  }

  return state.menuList.program;
};

export const getCategoryList = (state) => {
  if (!state.menuList) {
    return null;
  }

  return state.menuList.category;
};

export const getPodcastList = (state) => {
  if (!state.menuList) {
    return null;
  }

  return state.menuList.podcast;
};
