import { createAction, handleAction } from "redux-actions";
import { schema } from "normalizr";

import { getSlugId, mergeBySlug } from "../utilities";

/*
 *** NORMALIZR
 */

export const author = new schema.Entity("authors", undefined, {
  idAttribute: author => getSlugId(author.slug)
});

/*
 *** ACTION CREATORS
 */

export const storeAuthors = createAction("STORE_AUTHORS");

/*
 *** REDUCERS
 */

export default handleAction(
  storeAuthors,
  (state, { payload }) => ({
    ...state,
    ...mergeBySlug(state, payload)
  }),
  {}
);
