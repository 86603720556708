import { createActions, handleActions } from "redux-actions";
import { normalize } from "normalizr";

import { getData } from "../utilities";
import { story, storeStories } from "./stories";

/*
 *** ACTION CREATORS
 */

const actions = createActions({
  HOME_PAGE: {
    REQUEST: undefined,
    RESPONSE: undefined
  }
});

/*
 *** THUNKS
 */

export const fetchHomePage = () => dispatch => {
  dispatch(actions.homePage.request());

  return getData("/contents/home/")
    .then(async res => {
      if (res.status !== 200) {
        return dispatch(actions.homePage.response(new Error(res.statusText)));
      }

      const results = (await res.json()).results;
      let live;
      if (results.SECTION_LIVE.length) {
        live = normalize(results.SECTION_LIVE[0], story);
      }
      const frontRow = normalize(results.SECTION_HERO, [story]);
      const storyHighlight = normalize(results.SECTION_HIGHLIGHTED_STORIES, [
        story
      ]);
      const storyCarousel = normalize(results.SECTION_LASTEST_STORIES, [story]);
      const lastVideos = normalize(results.SECTION_LASTEST_PROGRAMS, [story]);

      dispatch(
        storeStories({
          ...(live ? live.entities.stories : {}),
          ...frontRow.entities.stories,
          ...storyHighlight.entities.stories,
          ...storyCarousel.entities.stories,
          ...lastVideos.entities.stories
        })
      );

      return dispatch(
        actions.homePage.response({
          live: results.SECTION_LIVE[0],
          frontRow: frontRow.result,
          storyHighlight: storyHighlight.result,
          programCarousel: results.SECTION_PROGRAMS_CAROUSEL,
          storyCarousel: storyCarousel.result,
          lastVideos: lastVideos.result
        })
      );
    })
    .catch(error => dispatch(actions.homePage.response(error)));
};

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      actions.homePage.request,
      state => ({
        ...state,
        isFetching: true,
        error: null,
        lastUpdate: new Date()
      })
    ],
    [
      actions.homePage.response,
      (state, { error, payload }) => {
        if (error) {
          return {
            ...state,
            isFetching: false,
            error: payload.message
          };
        }

        return {
          ...state,
          isFetching: false,
          isLoaded: true,
          ...payload
        };
      }
    ]
  ]),
  {
    isFetching: false,
    error: null,
    isLoaded: false,
    frontRow: [],
    storyHighlight: [],
    programCarousel: [],
    storyCarousel: [],
    lastVideos: []
  }
);

/*
 *** SELECTORS
 */

export const getHomePage = (state, storiesBySlug) => ({
  ...state,
  frontRow: state.frontRow.map(slug => storiesBySlug[slug]),
  storyHighlight: state.storyHighlight.map(slug => storiesBySlug[slug]),
  programCarousel: state.programCarousel,
  storyCarousel: state.storyCarousel.map(slug => storiesBySlug[slug]),
  lastVideos: state.lastVideos.map(slug => storiesBySlug[slug])
});
