import { createActions, handleActions } from "redux-actions";
import { normalize } from "normalizr";

import { getData, getSlugId } from "../utilities";
import { story, storeStories } from "./stories";

/*
 *** ACTION CREATORS
 */

const actions = createActions({
  TAG_PAGE: {
    REQUEST: [payload => payload, (_, slug, page) => ({ slug, page })],
    RESPONSE: [
      payload => payload,
      (_, slug, page, tag, nbrPages) => ({ slug, page, tag, nbrPages })
    ]
  }
});

export const fetchTagPage = (slug, page = 1) => dispatch => {
  dispatch(actions.tagPage.request(undefined, slug, page));

  return getData("/stories/", {
    tag_slug: slug,
    page,
    per_page: 12
  })
    .then(async res => {
      if (res.status === 404) {
        return dispatch(
          actions.tagPage.response(new Error(res.status), slug, page)
        );
      }

      if (res.status !== 200) {
        return dispatch(
          actions.tagPage.response(new Error(res.statusText), slug, page)
        );
      }

      const results = (await res.json()).results;

      let tag;
      if (results[0].story_tags.length) {
        const item = results[0].story_tags.find(tag => tag.slug === slug);
        if (item) {
          tag = item;
        }
      }
      if (!tag) {
        tag = results[0].primary_category;
      }

      const content = normalize(results, [story]);

      dispatch(storeStories(content.entities.stories));

      return dispatch(
        actions.tagPage.response(
          content.result,
          slug,
          page,
          tag,
          parseInt(res.headers.get("x-pagination-pages"), 10)
        )
      );
    })
    .catch(error => dispatch(actions.tagPage.response(error, slug, page)));
};
/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      actions.tagPage.request,
      (state, { meta }) => ({
        ...state,
        [getSlugId(meta.slug)]: {
          ...(state[getSlugId(meta.slug)] ? state[getSlugId(meta.slug)] : {}),
          pages: {
            ...(state[getSlugId(meta.slug)]
              ? state[getSlugId(meta.slug)].pages
              : {}),
            [meta.page]: { isFetching: true, error: null }
          }
        }
      })
    ],
    [
      actions.tagPage.response,
      (state, { error, payload, meta }) => {
        if (error) {
          return {
            ...state,
            [getSlugId(meta.slug)]: {
              ...state[getSlugId(meta.slug)],
              pages: {
                ...state[getSlugId(meta.slug)].pages,
                [meta.page]: { isFetching: false, error: payload.message }
              }
            }
          };
        }

        return {
          ...state,
          [getSlugId(meta.slug)]: {
            pages: {
              ...state[getSlugId(meta.slug)].pages,
              [meta.page]: { isFetching: false, error: null, content: payload }
            },
            tag: meta.tag,
            nbrPages: meta.nbrPages
          }
        };
      }
    ]
  ]),
  {}
);

export const getTagPage = (state, slug, page, storiesBySlug) => {
  const slugId = getSlugId(slug);
  const tagPage = state[slugId] && state[slugId].pages[page];

  if (!tagPage) {
    return {
      isFetching: false,
      error: null,
      tag: state[slugId] && state[slugId].tag,
      nbrPages: state[slugId] && state[slugId].nbrPages,
      content: []
    };
  }

  return {
    isFetching: tagPage.isFetching,
    error: tagPage.error,
    tag: state[slugId].tag,
    nbrPages: state[slugId].nbrPages,
    content:
      (tagPage.content && tagPage.content.map(slug => storiesBySlug[slug])) ||
      []
  };
};
