import { createAction, handleAction } from "redux-actions";
import { schema } from "normalizr";

import { getSlugId, mergeBySlug } from "../utilities";

/*
 *** NORMALIZR
 */

export const topic = new schema.Entity("topics", undefined, {
  idAttribute: (topic) => getSlugId(topic.slug),
});

/*
 *** ACTION CREATORS
 */

export const storeTopics = createAction("STORE_TOPICS");

/*
 *** REDUCERS
 */

export default handleAction(
  storeTopics,
  (state, { payload }) => ({
    ...state,
    ...mergeBySlug(state, payload),
  }),
  {}
);
