import { createActions, handleActions } from "redux-actions";
import { normalize } from "normalizr";

import { getData, getSlugId } from "../utilities";
import { topic, storeTopics } from "./topics";

/*
 *** ACTION CREATORS
 */

export const actions = createActions({
  TOPIC_PAGE: {
    REGISTER: undefined,
    REQUEST: [(payload) => payload, (_, slug) => ({ slug })],
    RESPONSE: [(payload) => payload, (_, slug) => ({ slug })],
  },
});

/*
 *** THUNKS
 */

export const fetchTopicPage = (slug) => (dispatch) => {
  dispatch(actions.topicPage.request(undefined, slug));

  return getData(`/topics/by-slug/${slug}`)
    .then(async (res) => {
      if (res.status === 404) {
        return dispatch(
          actions.topicPage.response(new Error(res.status), slug)
        );
      }

      if (res.status !== 200) {
        return dispatch(
          actions.topicPage.response(new Error(res.statusText), slug)
        );
      }

      const results = (await res.json()).results;
      const topicPage = normalize(results, topic);
      dispatch(storeTopics(topicPage.entities.topics));

      return dispatch(actions.topicPage.response(undefined, slug));
    })
    .catch((error) => dispatch(actions.topicPage.response(error, slug)));
};

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      actions.topicPage.register,
      (state, { payload }) => ({
        ...state,
        ...payload.reduce(
          (acc, page) => ({
            ...acc,
            [page]: { isFetching: false, error: null, isLoaded: true },
          }),
          {}
        ),
      }),
    ],
    [
      actions.topicPage.request,
      (state, { meta }) => ({
        ...state,
        [getSlugId(meta.slug)]: { isFetching: true, error: null },
      }),
    ],
    [
      actions.topicPage.response,
      (state, { error, payload, meta }) => {
        if (error) {
          return {
            ...state,
            [getSlugId(meta.slug)]: {
              isFetching: false,
              error: payload.message,
            },
          };
        }

        return {
          ...state,
          [getSlugId(meta.slug)]: {
            isFetching: false,
            error: null,
            isLoaded: true,
          },
        };
      },
    ],
  ]),
  {}
);

/*
 *** SELECTORS
 */

export const getTopicPage = (state, slug, topicsBySlug) => {
  const slugId = getSlugId(slug);
  const topicPage = state[slugId];

  if (!topicPage) {
    return {
      isFetching: false,
      error: null,
      isLoaded: false,
    };
  }

  return {
    isFetching: topicPage.isFetching,
    error: topicPage.error,
    isLoaded: topicPage.isLoaded || false,
    topic: topicPage.isLoaded && topicsBySlug[slugId],
  };
};
