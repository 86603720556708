import React from "react";
import styled from "styled-components";

import { menuHeight } from "../../theme";
import loader from "../../assets/loader-white.mp4";

const Container = styled.div`
  div {
    background: white;
    display: flex;
    height: 100vh;
    justify-content: center;
    padding: calc(${menuHeight} + 20px) 0 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  video {
    max-width: 210px;
  }
`;

function PageLoader() {
  return (
    <Container>
      Chargement ...
      <div>
        <video src={loader} autoPlay loop muted playsInline preload="auto" />
      </div>
    </Container>
  );
}

export default PageLoader;
