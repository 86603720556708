import "babel-polyfill";
import "cross-fetch/polyfill";

import * as Sentry from "@sentry/browser";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { hydrate } from "react-dom";
import { loadableReady } from "@loadable/component";
import { Provider } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements as StripeElements } from "@stripe/react-stripe-js";

import serviceWorker from "./serviceWorker";
import configureStore from "./configureStore";
import { initAccountToken, ScrollToTop } from "./utilities";
import Root from "./Root";

Sentry.init({
  dsn: process.env.RAZZLE_SENTRY_FRONTEND,
});
if (window.__INITIAL_STATE__.user.isConnected) {
  Sentry.setUser({ email: window.__INITIAL_STATE__.user.account.email });
}

const promiseStripe = loadStripe(process.env.RAZZLE_STRIPE_APIKEY);

initAccountToken();

const store = configureStore(window.__INITIAL_STATE__);
delete window.__INITIAL_STATE__;

loadableReady(() => {
  hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <StripeElements stripe={promiseStripe}>
            <Root />
          </StripeElements>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );
});

if (module.hot) {
  module.hot.accept();
}

serviceWorker.register();
