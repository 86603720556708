import { createActions, handleActions } from "redux-actions";
import * as Sentry from "@sentry/browser";

import { fetchShopData } from "../utilities";

/*
 *** ACTION CREATORS
 */

export const actions = createActions({
  REQUEST_RESET_PASSWORD: {
    REQUEST: undefined,
    RESPONSE: undefined,
  },
  MAKE_RESET_PASSWORD: {
    REQUEST: undefined,
    RESPONSE: undefined,
  },
});

export const requestResetPassword = (data) => (dispatch) => {
  dispatch(actions.requestResetPassword.request());

  Sentry.setContext("request", data);
  return fetchShopData("/account/reset-password-notification/", {
    method: "POST",
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      if (res.status !== 200) {
        return dispatch(
          actions.requestResetPassword.response(
            new Error("Une erreur est survenue, veuillez réessayer.")
          )
        );
      }

      const results = (await res.json()).results;
      return dispatch(actions.requestResetPassword.response(results));
    })
    .catch((error) => dispatch(actions.requestResetPassword.response(error)));
};

export const makeResetPassword = (data) => (dispatch) => {
  dispatch(actions.makeResetPassword.request());

  Sentry.setContext("request", data);
  return fetchShopData("/account/reset-password/", {
    method: "POST",
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      if (res.status !== 200) {
        return dispatch(
          actions.makeResetPassword.response(
            new Error("Une erreur est survenue, veuillez réessayer.")
          )
        );
      }

      const results = (await res.json()).results;
      return dispatch(actions.makeResetPassword.response(results));
    })
    .catch((error) => dispatch(actions.makeResetPassword.response(error)));
};

/*
 *** REDUCERS
 */

export default handleActions(
  new Map([
    [
      actions.requestResetPassword.request,
      (state) => ({
        ...state,
        request: {
          isFetching: true,
          error: null,
        },
      }),
    ],
    [
      actions.requestResetPassword.response,
      (state, { error, payload }) => {
        if (error) {
          Sentry.captureException(payload);

          return {
            ...state,
            request: { isFetching: false, error: payload.message },
          };
        }

        return {
          ...state,
          request: { isFetching: false, error: null, send: true },
        };
      },
    ],
    [
      actions.makeResetPassword.request,
      (state) => ({
        ...state,
        make: {
          isFetching: true,
          error: null,
        },
      }),
    ],
    [
      actions.makeResetPassword.response,
      (state, { error, payload }) => {
        if (error) {
          Sentry.captureException(payload);

          return {
            ...state,
            make: { isFetching: false, error: payload.message },
          };
        }

        return {
          ...state,
          make: { isFetching: false, error: null, send: true },
        };
      },
    ],
  ]),
  {
    request: { isFetching: false, error: null },
    make: { isFetching: false, error: null },
  }
);

/*
 *** SELECTORS
 */

export const getRequestResetPassword = (state) => state.request;
export const getMakeResetPassword = (state) => state.make;
