import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  campaignMenuHeight,
  mobileCampaignMenuHeight,
  menuHeight,
  mobileMenuHeight,
  menuOffset,
  mobileMenuOffset,
  colors,
  Button,
  useMinSmallDesktop,
  useMinTabletLandscape,
  useMinTabletPortrait,
} from "../../theme";
import SearchForm from "../search/SearchForm";
import logo from "../../assets/logo-lemedia.svg";
import logoWhite from "../../assets/logo-lemedia-white.svg";
import logoHovered from "../../assets/logo-lemedia-gradient.svg";

const Wrapper = styled.nav`
  height: ${(props) => (props.isHeightless ? "0" : menuOffset)};

  @media (max-width: 767px) {
    height: ${(props) => (props.isHeightless ? "0" : mobileMenuOffset)};
  }
`;

const Container = styled.div`
  background: white;
  box-shadow: ${(props) =>
    props.isShadowDisabled ? "0" : "0 3px 8px rgba(46, 68, 82, 0.25)"};
  height: ${menuHeight};
  padding: 24px 0;
  position: fixed;
  top: ${campaignMenuHeight};
  width: 100%;
  z-index: 10000;

  ${(props) =>
    props.isTransparent &&
    `
    background: rgba(0,0,0,0.25);
    border-bottom: 1px solid rgba(255,255,255,0.4);
    position: absolute;

    a, button{
      font-weight: 500;
    }
  `}

  @media(max-width: 767px) {
    height: ${mobileMenuHeight};
    padding: 18px 0;
    top: ${mobileCampaignMenuHeight};
  }

  a,
  button {
    color: ${(props) => (props.isTransparent ? "white" : "black")};
    font-size: 1.3rem;
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;

    :hover {
      color: ${colors.primaryColor};
      transition: 0.3s;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 1174px;

  @media (max-width: 1599px) {
    width: 788px;
  }

  @media (max-width: 1023px) {
    padding: 0 30px 0 118px;
    width: 100%;
  }

  @media (max-width: 767px) {
    display: block;
    padding: 0 0 0 ${mobileMenuHeight};
    text-align: center;
    width: 100%;
  }
`;

const Left = styled.div`
  position: relative;
  width: 100%;

  > a {
    display: inline-block;
    height: 40px;
    margin-right: 39px;
  }

  h1 {
    display: inline-block;
    position: relative;

    img {
      display: block;
      transition: 0.3s linear;

      @media (max-width: 767px) {
        height: 24px;
      }
    }

    :after {
      background: url(${logoHovered});
      background-size: cover;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
      transition: 0.3s linear;
    }

    :hover {
      img {
        opacity: 0;
      }

      :after {
        opacity: 1;
      }
    }
  }

  span {
    display: inline-block;
    transition: 0.3s;

    ${(props) =>
      props.isSearchVisible &&
      `
      opacity: 0;
      transform: translateY(30px);
      visibility: hidden;
    `}

    a, button {
      margin-right: 23px;

      :last-child {
        margin: 0;
      }

      .direct {
        background: ${colors.red};
        border: 1px solid ${colors.red};
        border-radius: 3px;
        color: white;
        font-weight: 700;
        padding: 2px 8px;

        &:hover {
          background: white;
          color: ${colors.red};
        }

        i {
          font-weight: 600;
          margin-right: 4px;
        }
      }
    }
  }

  form {
    height: 36px;
    left: 220px;
    opacity: 0;
    position: absolute;
    top: 3px;
    transform: translateY(-45px);
    transition: 0.3s;
    width: calc(100% - 220px);

    ${(props) =>
      props.isSearchVisible &&
      `
      opacity: 1;
      transform: translateY(0);
    `}

    input {
      ${(props) =>
        props.isTransparent &&
        `
        background: rgba(243, 243, 243, 0.2);
        border-bottom: 2px solid rgba(255, 255, 255, 0.3);
        color: white;

        ::placeholder {
          color: rgba(255, 255, 255, 0.6);
        }
      `}
    }
  }
`;

const Right = styled.div`
  flex-basis: content;
  white-space: nowrap;

  button {
    color: ${(props) => (props.isTransparent ? "white" : "black")};
    font-size: 1.8rem;
    margin: 0 8px 0 23px;
    vertical-align: middle;

    :hover {
      color: ${colors.primaryColor};
      transition: 0.3s;
    }
  }

  a {
    margin-left: 23px;

    :last-of-type {
      color: white;
      margin: 3px 0 0 31px;
    }
  }
`;

function Menu({
  isConnected,
  isTransparent,
  isShadowDisabled,
  toggleBurgerMenu,
}) {
  const isMinTabletLandscape = useMinTabletLandscape();
  const isMinTabletPortrait = useMinTabletPortrait();
  const isMinSmallDesktop = useMinSmallDesktop();
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const searchInputRef = useRef();

  function toggleSearch() {
    setIsSearchVisible((state) => !state);
    searchInputRef.current.focus();
  }

  return (
    <Wrapper
      isHeightless={isTransparent}
      role="navigation"
      aria-label="Menu principal"
    >
      <Container
        isTransparent={isTransparent}
        isShadowDisabled={isShadowDisabled}
      >
        <Flex>
          <Left isSearchVisible={isSearchVisible} isTransparent={isTransparent}>
            <Link to="/">
              <h1>
                <img src={isTransparent ? logoWhite : logo} alt="Le Média" />
              </h1>
            </Link>
            {isMinTabletPortrait && (
              <span>
                <button onClick={toggleBurgerMenu}>Le menu</button>
                {isMinTabletLandscape && (
                  <a href="https://lemediatv.fr/communaute/forum">Le forum</a>
                )}
                {isMinSmallDesktop && (
                  <a
                    href="https://video.lemediatv.fr/c/chaine_officielle/videos"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Peertube
                  </a>
                )}

                <Link to="/direct">
                  <span className="direct">
                    <i className="far fa-satellite-dish" /> Le Direct
                  </span>
                </Link>
              </span>
            )}
            <SearchForm inputRef={searchInputRef} />
          </Left>
          {isMinTabletPortrait && (
            <Right isTransparent={isTransparent}>
              <button
                onClick={toggleSearch}
                title={
                  isSearchVisible
                    ? "Fermer la recherche"
                    : "Faire une recherche"
                }
              >
                {isSearchVisible ? (
                  <i
                    className={`${
                      isTransparent ? "far" : "fal"
                    } fa-times fa-fw`}
                  />
                ) : (
                  <i
                    className={`${
                      isTransparent ? "far" : "fal"
                    } fa-search fa-fw`}
                  />
                )}
              </button>
              {isMinTabletLandscape &&
                (isConnected ? (
                  <Link to="/compte">Mon Compte</Link>
                ) : (
                  <Link to="/connexion">Se connecter</Link>
                ))}
              <Button as={Link} to="/soutien">
                Soutenir
              </Button>
            </Right>
          )}
        </Flex>
      </Container>
    </Wrapper>
  );
}

Menu.propTypes = {
  isConnected: PropTypes.bool.isRequired,
  isTransparent: PropTypes.bool,
  isShadowDisabled: PropTypes.bool,
  toggleBurgerMenu: PropTypes.func.isRequired,
};

export default Menu;
